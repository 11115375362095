// **** VIEW ****

article {
    width: 100%;
    padding: 1.3em 0;
    h1 {
        color: white;
        margin: 0;
        font-family: Tex-bold !important;
        font-size: 2em;
    }
    
    h3,h4 {
        text-transform: uppercase;
        padding: 0; margin: 0;
    }
    
    header {
        padding-bottom: 2em;
    }
    
    .about::first-letter{
        font-size: 1.2em;
        color: white;
    }

    address{
        padding-bottom:  0em;
    }
    section {
        padding: 2em 0;
    }
    .flex-end {
        align-items: flex-end;
    }
    
    .phone {
        color: #ddd;
    }
    
    ul.ext-links {
        list-style: none;
        text-align: left;
        font-size: .8em;
        margin: 0; padding: 0;
        li {
            margin: 1enm 0em;
        }
        a {
            color: inherit;
            &:hover { color: white;}
        }
    }
    .spot-view-nav{
        display: flex;
        justify-content: space-between;
        a{
            color: #aaa;
            padding: .5em;
            text-transform: uppercase;
            border-bottom: 3px solid transparent;
            
            &:hover {
                color: white;
            }
            &.selected {
                color: white;
                border-color: white;
            }
        }
    }
    
}
.ns-meta{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ul.ns-app-info{
   list-style: none;
   font-family: Tex-reg;
   margin: 0; padding: 0;
   text-align: right;
   font-size: 1em;
   color: #ddd;
    .distance {
        color: $drink;
    }
    
    .favorite-btn{
        cursor: pointer;
        background: #151515;
        border: 1px solid #333;
        border-radius: .2em;
        padding: 0 .4em;
    }
}

div.nested-view {
    &.ng-enter,
    &.ng-leave {
    transition: all 200ms ease;
    }

    &.ng-enter {
        opacity: 0;
        transform: translateX(3%);
    }
    
    &.ng-leave {
        opacity: 1;
    }
    &.ng-enter.ng-enter-active {
        opacity: 1;
        transform: translateX(0%);
   }

    &.ng-leave.ng-leave-active {
        opacity: 0;
    }
}

// **** LIST ****
.ns-spot-block{
    padding: 2em 0 !important;
//    margin: 2em 0; 
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
    transition: all .5s;
    h3 {
        color: white;
        padding: 0; margin: 0;
    }
    a{
        color: white;
        font-size: .9em;
    }
    border-bottom: 1px solid #151515;
    &.highlight {
        border-top: 3px solid $primary;
        img {
            transform: scale(1.1);
        }
    }
}

ul.ns-items-list{
    list-style-type: none;
    margin: 0; padding: 2em 0;
    font-size: .9em;
    
    li{
        display: block;
        width: 100%;
    }
    
    .date,
    .title,
    .value{
        font-family: Tex-reg;
        text-transform: uppercase;
        color: #ddd;
        i{
            color: $primary;
        }
    }
    
    .date {
        color: white;
        border-bottom: 1px solid #222;
        padding-bottom: .2em;
        margin-bottom: .2em;
    }
    
    .full{
        display: block;
    }

    .edit-links {
        opacity: 0;
        transition: all .5s;
        color: #222 !important;
        padding-left: .5em;
    }

    li:hover .edit-links {
        opacity: 1;
    }
    
   
}

.ns-thumb {
    height: 120px;
    overflow: hidden;
    margin-bottom: 1em;
    border-radius: $border-radius;    
    span {
       display: block;
       font-size: .8em;
       padding: .5em 1em;
       border-radius: 100%;
       border: 1px solid;
       background: #000;
       color: white;
       text-shadow: 1px 1px black;
       font-weight: bold;
        z-index: 99999;
       position: absolute;
       top: -1.5em;
       left: -.3em; 
    }
    
    .eat-index {
        border-color: $eat;
        background: $eat;
    }
    .drink-index {
        border-color: $drink;
        background: $drink;
    }
    .attend-index {
        border-color: $attend;
        background: $attend;
    }
    .home-index {
        border-color: white;
        background: black;
    }
    
    .fav-index{
        border-color: $primary;
        background: $primary;
    }

    border: 1px solid #151515;

    img {
         margin-top: -20%;
         width: 100%;
         height: auto;
         z-index: -1;
         transition: all .3s;
    }
}

ul.ns-gallery {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    margin: 0; padding: 0;
    font-size: .7em;
    li{
        transition: all .2s ease-in-out; 
        margin: 0; padding: 0;
        width: 50%;
        position: relative;
        .ns-img-ctrls{
            position: absolute;
            bottom: 0;
            right: 0;
            display:block;
            height: 0px;
            overflow: hidden;
            transition: all .2s ease-in-out;
            button {
                background: white;
                border: none;
                color: black;
            }
        }
        img{
            width: 100%;
            height: auto;
        }
        &:hover {
            border: 4px solid white;
            transform: scale(1.1);
            z-index: 99999;
            .ns-img-ctrls{
                height: 2em;
            }
            
        }
    }
   
}


ul.ns-features-list{
    font-family: Tex-reg;
    color: #ddd;
//    font-size: 1.2em;
    line-height: 2em;
    list-style: none;
    padding: 0; margin: 0;
    text-transform: uppercase;
    margin-bottom: 2em;
}
ul.ns-payment-opts{
    display: flex;
    list-style: none;
    margin: 0; padding: 0;
    color: white;
    font-size: 2em;
    justify-content: space-around;
}

.ns-primary-image{
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    
    img {
        width: 100%;
        height: auto;
    }

    &:hover > .ns-primary-image-opts {
       height: 50px;
       padding: .5em 1em;
    }
}

.ns-primary-image-opts {
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0px;
        background: rgba(0,0,0,0.9);
        transition: all 400ms;
    
    button {
        background: none;
        border: none;
        color: #a5a5a5;
        font-size: 1.2em;
        transition: all 400ms;
        &:hover {
            color: white;
        }
    }
}


.ns-report{
    cursor: pointer;
    font-size: .7em;
    color: #888;
    transition: all 0.5s;
    &:hover{
        color: #999;
    }
}
    
.ns-last-updated {
    font-size: .7em;
    color: #888;
    display: block;
    text-align: right;
}

.ns-walk{
    font-size: .8em;
    color: #999;
}



@keyframes pulse {
    0%{
       color: white;
    }
    
    50% {
       color: $primary; 
    }
    
    100% {
       color: white;
    }
}

.ns-pulse {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}


@media (min-width: 34em) {
    article {
     padding: 2em 0;
        h1 {
            font-size: 3em;
        }
        address{
             padding-bottom: 1em;
        }
    }
    .ns-thumb{
        height: auto;
        img {
            margin-top: 0;
        }
    }
    .ns-spot-block{
       padding: 4em 0 !important;
    }
    
//    .ns-spot-block.highlight {
//        
//        border-top: none;
//        border-left: 3px solid $primary;
//    }
}
//@media (min-width: 48em) {}
//@media (min-width: 62em) {}
//@media (min-width: 75em) {}