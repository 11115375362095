#ns-splash {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
    background: #000 url(../img/layout/stars.png) repeat top center;
    // background-color: rgba(0,0,0,1);
    // background-image: url('../img/layout/nslogo.svg');
    // background-repeat: no-repeat;
    // background-size: 400px;
    // background-position: center 25%;
    z-index: 99999;
    overflow: auto;
    padding-top: 1em; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: all 1s;
    visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
    
    h4 {
         z-index: 9999999;
    }

    img {
        width: 400px;
        height: auto;
        z-index: 9999999;
    }
    
    &.show {
        visibility: visible;
	    opacity: 1;
	    -webkit-transform: scale(1);
	    transform: scale(1);	
	    -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	    transition: transform 0.4s, opacity 0.4s;
    }
}

#ns-twinkle {
    position: absolute;
    background:transparent url(../img/layout/twinkling.png) repeat top center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 999999;
    -moz-animation:twinkle 900s linear infinite;
    -ms-animation:twinkle 900s linear infinite;
    -o-animation:twinkle 900s linear infinite;
    -webkit-animation:twinkle 900s linear infinite;
    animation:twinkle 900s linear infinite;
}

#container {
  margin-top: 20px;
  margin-left: 30px;
  position: relative;
  background: #45453f;
  z-index: 9999999;
}

.dot {
  width: 10px;
  height: 10px;
  border: 5px solid $primary;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: $primary;
  z-index: 10;
  position: absolute;
}

.ns-dot-pulse {
  border: 3px solid $primary;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: dotPulse 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: dotPulse 2s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: dotPulse 2s ease-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 1;
  opacity: 0;
}

@keyframes dotPulse {
  0% {
    transform: scale(0);
    opacity: 0.0;
 }
 25% {
    transform: scale(0.3);
    opacity: 0.1;
 }
 50% {
    transform: scale(0.5);
    opacity: 0.3;
 }
 75% {
    transform: scale(1);
    opacity: 0.5;
 }
 100% {
    transform: scale(1.5);
    opacity: 0.0;
 }  
}

@-moz-keyframes dotPulse {
 0% {
    -moz-transform: scale(0);
    opacity: 0.0;
 }
 25% {
    -moz-transform: scale(0.3);
    opacity: 0.1;
 }
 50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
 }
 75% {
    -moz-transform: scale(1);
    opacity: 0.5;
 }
 100% {
    -moz-transform: scale(1.5);
    opacity: 0.0;
 }
}

@-webkit-keyframes "dotPulse" {
 0% {
    -webkit-transform: scale(0);
    opacity: 1;
 }
 25% {
    -webkit-transform: scale(0.2);
    opacity: 1;
 }
 50% {
    -webkit-transform: scale(0.5);
    opacity: 1;
 }
 75% {
    -webkit-transform: scale(0.7);
    opacity: 1;
 }
 100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
 }
}

@keyframes twinkle {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes twinkle {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-moz-keyframes twinkle {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-ms-keyframes twinkle {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

 

@media (min-width: 34em) {
    #ns-splash {
        background-size: 640px !important;
        background-position: center 35% !important;
    }
}
//@media (min-width: 48em) {}
//@media (min-width: 62em) {}
//@media (min-width: 75em) {}