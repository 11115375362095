html,
body {
    height: 100%;
    width: 100%;
}
body{
    display: flex;
    flex-direction: column;
    overflow:hidden;
}
ui-view{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;  
    
    &.ng-enter,
    &.ng-leave {
    transition: all 200ms ease;
    }

    &.ng-enter {
        opacity: 0;
        transform: translateY(1%);
    }
    
    &.ng-leave {
        opacity: 1;
    }
    &.ng-enter.ng-enter-active {
        opacity: 1;
        transform: translateY(0%);
   }

    &.ng-leave.ng-leave-active {
        opacity: 0;
    }
}
footer {
    flex: 1;
}
nav#main{
    flex: 0 0 10%;
    order: 3;
    display: flex;
//    align-items: stretch;
    background: #111;
    
    ul{
        display: flex;
        flex: 1 0 0;
        justify-content:  space-between;
        align-items: stretch;
        padding: 0; margin: 0;
        list-style-type: none;
        
        
        li{
            display: flex;
            flex: 1 0 0;
            border-top: 3px solid transparent;
            border-left: 1px solid #222 !important;
            
            a{ 
                display: block;
                width: 2.5em; height: 2.5em;
                margin: auto;
                text-indent: -9999px;
                background-size: 2.5em;
                background-repeat: no-repeat;
                background-position: center center;
                transition: all .4s;
                
                &:hover { text-decoration: none;}
                &.home-link { background-image: url('../img/layout/home.png');}
                &.eat-link { 
                    background-image: url('../img/layout/eat.png');
                    &:hover{ color: $eat;} 
                }
                &.drink-link { 
                    background-image: url('../img/layout/drink.png');
                    &:hover{ color: $drink;} 
                }
                &.attend-link { 
                    background-image: url('../img/layout/attend.png');
                    &:hover{ color: $attend;} 
                }
                &.menu-link{ }
                    
                }
            }
        }

}
.ns-content{
    flex: 0 0 90%;
    width: 100%;
    order: 1;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    section {
        width: 100%;
    }
    
    
}
.ns-fullwidth{
    width: 100%;
}
.split {
    flex-basis: 60% !important;
}
.ns-map-container {
    background: gray;
    flex: 0 0 30%;
    height: 30%;
    width: 100%;
    position: relative;
}
#map {
    height: 100% !important;
    width: 100% !important;
    background: black;
}

div#ns-topbar{
    display: flex;
    align-items: center;
    margin: 1em 0;
    justify-content:center;
    flex-wrap: nowrap;
    
    input {
        border-radius: 2em;
        background: none;
        border: 1px solid #222;
        outline: none;
        padding: .375rem 2em;
        background-image: url(../img/layout/search.png);
        background-size: 1.5em 1.5em;
        background-repeat: no-repeat;
        background-position: .4em center;
    }

    ul li a.ns-user-menu-btn {
            display: inline-block;
            width: 2.5em;
            height: 2.5em;
            margin-left: 1em;
            margin-bottom: -.8em;
            border-radius: 2em;
            overflow: hidden;
    }
}

footer {
    margin-top: .5em;
    padding: .5em;
    color: #666;
    font-size: .7em;
    display: flex;
    justify-content: space-around;
    text-align: center;
    
    a{
        color: #8C008C;
    }
}

@media (min-width: 34em) { 
    div#ns-topbar{
         margin: 2.5em 0;
         justify-content: space-between;
    }
}


@media (min-width: 48em) {
    
    nav#main ul li:last-child {
        display: none;
    }

    
}

@media (min-width: 62em){
    body,
    ui-view{
        flex: 0 0 85%; // !!!IMPORTANTANTANTANTTNATANTATNTANTNATATNNANTNANANNT
        flex-direction: row !important;
    }
    nav#main {
        flex: 0 0 15%;
        order: 0;
        display: flex;
        border-right: 1px solid #171717;
        font-size: 1.2em;
        background-image: url(../img/layout/n.svg);
        background-color:black;
        background-size: 6em 6em;
        background-repeat: no-repeat;
        background-position: center 2.5em;
            
        ul {
            flex-direction: column;
            flex: 1 0 0;
            justify-content: center;
            align-items: flex-start;
            background: none;
            
            li {
                display: inline;
                flex: none;
                padding: 1em 2em;
                border-top: none;
                color: black;
                border-left: 3px solid !important;
                 &:last-child { display: none; }
                a { 
                    display: inline;
                    background: none;
                    color: white;
                }
            }
        }
    }
    
    .ns-content {
        flex: 0 0 100%;
        order: 0;
    }
    .split{
        flex-basis: 70% !important;
    }  
    .ns-map-container {
        flex: 0 0 30%;
        height: 100% !important;
        width: 100% !important;
    }
}//media @62em
@media (min-width: 75em) { }