// angular-loading-bar
#loading-bar .bar {
  background: $primary;
}

#loading-bar .peg {
  -moz-box-shadow: $primary 1px 0 6px 1px;
  -ms-box-shadow: $primary 1px 0 6px 1px;
  -webkit-box-shadow: $primary 1px 0 6px 1px;
  box-shadow: $primary 1px 0 6px 1px;
}

#loading-bar-spinner .spinner-icon {
  border-top-color:  $primary;
  border-left-color: $primary;
}

.pac-container {
    background-color: #000;
    position: absolute !important;
    z-index: 999999;
    border-radius: 2px;
    border-top: 1px solid #171717;
    font-family: Arial,sans-serif;
    box-shadow: 0 2px 6px rgba(0,0,0,0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 0.9;
    
    .pac-item{   
        cursor: default;
        padding: 1em 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1.3em;
        text-align: left;
        border-top: 1px solid #171717;
        font-size: 1em;
        font-family: Tex-reg;
        color: #555;

        span {
            font-size: 1em;
            color: #cecece;
        }
        
        &:hover {
            background: #333;
        }
        .pac-icon {}
        .pac-icon-marker{}
        .pac-item-query{
            .pac-matched {
                color: $primary;
            }
        }
    }
}

// Toaster

/* Variables */
$textColor: #ffffff !default;
$textColorHover: #cccccc !default;
$closeButton: #ffffff !default;
$closeButtonHover: #000000 !default;

$fontSize: 20px !default;

$toast: $primary; //#030303 !default;
$toastSuccess: $eat;// #51a351 !default;
$toastError: $drink;// #bd362f !default;
$toastInfo: $attend;// #2f96b4 !default;
$toastWarning: #f89406 !default;


$toastPositionFullWidthTop: 0 !default;
$toastPositionFullWidthBottom: 0 !default;

$toastPossitionTop: 1em !default;
$toastPossitionLeft: 1em !default;
$toastPossitionRight: 1em !default;
$toastPossitionBottom: 1em !default;

$toastContainerColor: #ffffff !default;
$toastContainerShadowColor: none !default;
$toastContainerShadowColorHover: none !default;


.toast-title {
  font-weight: normal;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  a, label {
    color: $textColor;
  }
  a:hover {
    color: $textColorHover;
    text-decoration: none;
  }
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: $fontSize;
  font-weight: bold;
  color: $closeButton;
  -webkit-text-shadow: 0 1px 0 $closeButton;
  text-shadow: 0 1px 0 $closeButton;
  opacity: 1;

  &:hover, &:focus {
    color: $closeButtonHover;
    text-decoration: none;
    cursor: pointer;

  }
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-full-width {
  top: $toastPositionFullWidthTop;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: $toastPositionFullWidthBottom;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: $toastPossitionTop;
  left: $toastPossitionLeft;
}

.toast-top-center {
  top: $toastPossitionTop;
}

.toast-top-right {
  top: $toastPossitionTop;
  right: $toastPossitionRight;
}

.toast-bottom-right {
  right: $toastPossitionRight;
  bottom: $toastPossitionBottom;
}

.toast-bottom-center {
  bottom: $toastPossitionBottom;
}

.toast-bottom-left {
  bottom: $toastPossitionBottom;
  left: $toastPossitionLeft;
}

.toast-center {
  top: 45%;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  opacity: 1;
  font-family: Tex-reg;
  /*overrides*/
  &.toast-center, &.toast-top-center, &.toast-bottom-center {
    width: 100%;
    pointer-events: none;
  }
  &.toast-center > div, &.toast-top-center > div, &.toast-bottom-center > div {
    margin: auto;
    pointer-events: auto;
  }
  &.toast-center > button, &.toast-top-cente > button, &.toast-bottom-center > button {
    pointer-events: auto;
  }
  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  > {
    div {
      margin: 0 0 6px;
      padding: 1em;
      width: 300px;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px ;
      border-radius: 8px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: $toastContainerColor;
 
      background: black;
      border: 2px solid;
      opacity: 1 !important;
   
        
        &.toast {
          color: $attend;
          border-color: $attend;
        }

        &.toast-success {
          color: $eat;
          border-color: $toastSuccess;
        }

        &.toast-error {
             color: $drink;
          border-color: $toastError;
        }

        &.toast-info, .toast-wait {
             color: $primary;
          border-color: $primary;
        }

        &.toast-warning {
          border-color: $toastWarning;
        }
    }
    :hover {
      scale: 1.1;
      cursor: pointer;
    }
    .toast-info,
    .toast-wait,
    .toast-error, 
    .toast-success,
    .toast-warning {
        background-image: none !important;
    }
      
  }
  &.toast-top-full-width > div, &.toast-bottom-full-width > div {
    width: 96%;
    margin: auto;
  }
}



/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container {
    > div {
      padding: 8px 8px 8px 50px;
      width: 11em;
    }
    .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container {
    > div {
      padding: 8px 8px 8px 50px;
      width: 18em;
    }
    .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

/*
 * AngularJS-Toaster
 * Version 0.3
*/

:not(.no-enter)#toast-container > div.ng-enter, 
:not(.no-leave)#toast-container > div.ng-leave {
    transition: all 400ms;
 transform: translateX(100%);
}

:not(.no-enter)#toast-container > div.ng-enter.ng-enter-active {
   opacity: 1;
   transform: translateX(0%);    
}

:not(.no-leave)#toast-container > div.ng-leave {
  opacity: 1;
  transform: translateX(0%); 
  &.ng-leave-active {
    opacity: 0;
    transform: translateX(100%); 
  }
    
}

:not(.no-enter)#toast-container > div.ng-enter {
  opacity: 0;
  transform: translateX(100%); 
}

/*
 * UIB DATEPICKER
 *
*/

table.uib-datepicker{
    tr{
        color: red;
    }
}

div.uib-datepicker{
    color: white;
    background: black;
}

/*
 * UIB TIMEPICKER
 *
*/

div.uib-timepicker{
    
}
table.uib-timepicker{
    
    .uib-separator {
        padding-right: .8em;
    }
    button {
        padding: .2em 1em;
        background: none;
        border: 1px solid #333;
        
    }
    .btn-link {
        color: white;
        padding: .1em 1.2em;
    }
    .glyphicon.glyphicon-chevron-up:before{
        content: "\f077";
	    font-family: FontAwesome;
    }
    .glyphicon.glyphicon-chevron-down:before{
        content: "\f078";
	    font-family: FontAwesome;
    }
}




