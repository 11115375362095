.ns-event-block {
    display: flex;
    padding: .5em 0;
    h4{
        font-size: 1.1em;
        margin-bottom: .1em;
        line-height: 1.4em;
    }
    p{font-size: .9em;}
    .occurrence {
        line-height: 1.4em;
        font-family: Tex-reg;
        font-size: .9em;
        margin-bottom: .5em;
        span {
            color: white;
            display: block;
        }
    }    
    
    ul.ns-event-contact,
    ul.ns-event-details {
        list-style: none;
        margin: 0 0 .5em 0; padding: 0;
        font-size: .8em;
        text-transform: uppercase;
        
        li{
            display: inline-block;
            &:first-child:before{content: none;}
            &:before{
                color: #333;
                content: '/';
                padding: .4em;
            }
        }
    }
}
