#ns-filters{
    display: flex;

//    align-items: flex-end;
   margin: 1.5em 0;
   justify-content:space-between;

//    
//    .ns-filter-btn {
//        @include moon-border();
//        width: 2.5em;
//        height: 2.5em;
//        text-indent: -99999px;
//        padding: .375rem 0;
//        border-radius: 2.5em;
//        background-color: transparent;
//        background: url('../img/layout/cross-sprite.png');
//        background-size: 2em;
//        background-position: center .2em;
//        background-repeat: no-repeat;
//        outline: none;
//        position: relative;
//        
//        .ns-filter-count{
//           display: block;
//           font-size: .6em;
//           padding: .5em 1em;
//           border-radius: 100%;
//           border: 1px solid black;
//           font-weight: bold;
//           color: #333;
//           background: rgba(0,0,0,.9);
//           position: absolute;
//           right: -1.5em;
//           bottom: -1.5em;  
//        }
//        
//        &.food-toggle-btn {
//             background-image: url('../img/layout/eat.png');
//        }
//        &.drink-toggle-btn {
//             background-image: url('../img/layout/drink.png');
//        }
//        &.attend-toggle-btn {
//             background-image: url('../img/layout/attend.png');
//        }
//        
//        &.feature-toggle-btn {
//            
//        }
//        
//        &.location-toggle-btn{
//            background-image: url('../img/layout/cross-sprite.png');       
//        }
//        
//        &.user-location-selected {
//            border: none;    
//            background-color: white;
//            background-position: center -1.8em;
//        }
//    }
//
//        select{
//            background: none;
//            border: none;
//            color: white;
//            outline: none;
//            padding: 0 1em;
//        }
//    
//        input[type=text]{
//            @include moon-border(); 
//            padding: 0;
//            height: 2.5em;
            
//            font-size: .8em;
//            width: 2.5em;
//            height: 2.5em;
//            margin-left: 1em;
//            border-radius: 2em;
//            text-indent: -9999px;
//            outline: none;
////            padding: .375rem 0em;
//            background-color: none;
//            background: url('../img/layout/location-sprite.png');
//            background-size: 2em;
//            background-repeat: no-repeat;
//            background-position: .2em .3em;
//            transition: width 1s;
    
//            
//            &:focus,
//            &.selected {
//                width: 16em;
//                text-indent: 0px;
//                color: #222;
//                padding-left: 2em;
//                border:none;
//                background-color: white;
//                background-position: .2em -1.7em;
//            }
 //       }
//
}

.ns-filter-btn {
    @include moon-border();
    width: 2.5em;
    height: 2.5em;
    text-indent: -99999px;
    padding: .375rem 0;
    border-radius: 2.5em;
    background-color: transparent;
    background-repeat: no-repeat;
    outline: none;
    position: relative;
    background-size: 2em;
    background-position: center .2em;
    
    .ns-filter-count{
       display: block;
        text-indent: 0px;
       font-size: .6em;
       padding: .5em 1em;
       border-radius: 100%;
       border: 1px solid black;
       font-weight: bold;
       color: red;
       background: rgba(0,0,0,.9);
       position: absolute;
       right: -1.5em;
       bottom: -1.5em;  
    }
    
        
    &.eat-toggle-btn {
        background-image: url('../img/layout/eat.png');
    }
    &.drink-toggle-btn {
        background-image: url('../img/layout/drink.png');
    }
    &.attend-toggle-btn {
        background-image: url('../img/layout/attend.png');
    }
    &.feature-toggle-btn {
        background-image: url('../img/layout/feature.png');
    }
    
    &.location-toggle-btn{
        background-image: url('../img/layout/marker.png');
    }
}

.ns-clear-filters{
    background: none;
    border:none;
    font-size: .8em;
    color: #777;
}
.ns-today{
    display: inline-block;
    font-family: Tex-reg;
    text-transform: uppercase;
    color: white; 
    font-size: 1.2em;
    margin-bottom: 1em;
}
.ns-order-select {
    display: inline-block;
    float: right;
    color: #aaa;
    background: none;
    border: none;
    font-family: Tex-reg;
    font-size: .9em;
    select{
        color: white;
        border-bottom: 1px solid white;
        border-radius: 0;
        box-shadow: none;
        margin-left: .2em;
    }
}
@media (min-width: 34em) { 

}
//@media (min-width: 48em) {}
//@media (min-width: 62em) {}
//@media (min-width: 75em) {}