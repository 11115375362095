.ns-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.9);
    z-index: 99999;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1em;
    
    visibility: visible;
	-webkit-transform: translateY(0%) scale(1);
	transform: translateY(0%) scale(1);
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
    
    &.ng-hide {
        visibility: hidden;
	   -webkit-transform: translateY(60%) scale(0);
	   transform: translateY(60%) scale(0);
	   -webkit-transition: -webkit-transform 0.4s, visibility 0s 0.4s;
	   transition: transform 0.4s, visibility 0s 0.4s;
    }
    

    nav {
        margin-top: 10%;
        display: flex;
        flex-direction: column;
        width: 100%;
        
        a{color: white;
          font-size: 1.75rem;
            i{
            }
        }
        .divider {
            border: 1px solid #252525;
            margin: .5em 0;
        }     
    }
    
    ul {font-size: 1.4em; }
       
//    input[type=text] {
    .search-input,
    .ns-pac-input {
        align-self: flex-start;
        background: none;
        margin: 1em 0;
        font-size: 2em;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #333;
        background-repeat: no-repeat;
        background-size: 1.5em;
        background-position: left center;
        padding-left: 2em;
        outline: none;
        width: 100%;
        color: $primary;
    }
    .search-input {
        background-image: url(../img/layout/search.png);
    }
    
    .ns-pac-input {
         background-image: url(../img/layout/marker.png);
    }

}
  
.x-overlay  {
    width: 30px;
    height: 30px;
    position: fixed;
    right: 20px;
    top: 20px;
    overflow: hidden;
    border: none;
    background: url(../img/layout/x.png) no-repeat center center;
    cursor: pointer;
    color: transparent;
    outline: none;
    z-index: 100;
}

.ns-overlay-spot-block{
    padding: 1em 0 !important;
    margin: .5em 0; 
    h3 {
        color: white;
        padding: 0; margin: 0;
    }
    a{ 
        color: white;
        font-size: .9em;
        display: block;

    }
    border: 1px solid $primary;
    border-radius: $border-radius;
    
    button{
        margin-top: 1em;
    }
    
    &.ng-enter,
    &.ng-leave,
    &.ng-move {
      -webkit-transition: 0.5s linear all;
      -moz-transition: 0.5s linear all;
      -o-transition: 0.5s linear all;
      transition: 0.5s linear all;
    }

    &.ng-enter,{
      opacity: 0;
      overflow: hidden;
    }

    &.ng-enter.ng-enter-active {
      opacity: 1;
    }

    &.ng-leave {
      opacity: 1;
    }

    &.ng-leave.ng-leave-active {
      opacity: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
}

@media (min-width: 34em) {
    
    .ns-guest-link{
       display: none;
    }
    
    .x-overlay  {
        right: 40px;
        top: 30px;
    }
}
@media (min-width: 48em) {
    
    .x-overlay{
        width: 50px;
        height: 50px; 
    }
}
//@media (min-width: 62em) {}
//@media (min-width: 75em) {}