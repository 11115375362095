$eat: #4DFF4D;
$drink: #FF4D4D;
$attend: #4DD2FF;
$primary: #FF4DFF;

.eat {
    color: $eat !important;
    border-color: $eat !important;
}
.drink {
    color: $drink !important;
    border-color: $drink !important;
}
.attend {
    color: $attend !important;
    border-color: $attend !important;
}
.white {
    color: #fff !important;
    border-color: #fff !important;
}
.loud {
    color: $primary !important;
    border-color: $primary !important;
}
//.row > div { TODO // FIX FOR COLLAPSE GLITCH...
//    overflow: hidden;
//}

// **** TYPO **** 


// font-awseome path

$fa-font-path:  "../fonts/fa" !default;

@font-face {
    font-family: Tex-reg;
    src: url('../fonts/tex/texgyreadventor-regular-webfont.ttf');
}
@font-face {
    font-family: Tex-bold;
      src: url('../fonts/tex/texgyreadventor-bold-webfont.ttf');
}

h1,h2,h3,h4,h5,h6,
a,
address,
input,
select,
.jumbotron {
    font-family: Tex-reg, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
a,
a:hover,
a:visited,
a:active,
a:focus{
    cursor: pointer;
    text-decoration: none !important;
}

button{
    outline: none !important;
}
address {
    text-transform: uppercase;
    margin: 0 !important;
    padding: 0; 
    .street{
        color: #ddd;
    }
}

table.ns-hours {
    font-family: Tex-reg;
    font-size: .8em;
    color: white;
    th{
        text-align: left !important;
    }

    td{
        padding-right: .5em;
        &::last-child{
            text-transform: lowercase;
        }
    }
    @media (min-width: 34em) { 
        td{
            padding-right: .8em;
        }
    }
}

ul.ns-tags {
    font-family: Tex-reg, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    list-style: none;
    padding: 0 0 1em 0; margin: 0;
    font-size: .8em;
    text-transform: uppercase;
    li {
        display: inline-block;
        &:first-child:before{
            content: none;
            font-family: Tex-bold;
        }
        &:before{
            color: #333;
            content: '/';
            padding: .4em;
        }
    }
}



::-webkit-scrollbar {
    -webkit-appearance: none !important;
}

.ns-hr{
    border-color: #171717;
}

img{
    image-rendering: -moz-crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}
@mixin moon-border{
    border-top: 1px solid #333;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #333;
    border-right: 1px solid #fff;
}

.modal-body {
    font-size: .9em;
}
