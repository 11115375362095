label {
    color: #aaa;
    font-family: Tex-reg;
}
input[type="time"]{
    line-height: 2em !important;
}

input:-webkit-autofill {
    background-color: #111 !important;
}

.c-select {
    background: #000 url('../img/layout/select-arrow.png') no-repeat right 0.75rem center;
    border-color: #333 ;
    color: white !important;
}
.ns-fb-signin{
    color: white;
    background: #3b5998;
    margin-top: 2em;
    &:hover{
        color: white;
    }
}

.ns-selectable-reset {
    background: none;
    border: 1px solid #aaa;
    border-radius: $border-radius;
    font-size: .9em;
    color: #ddd;
    margin: 1em;
}
ul.ns-selectable-list{
    list-style: none;
    margin: 1.7em 0; padding: 0;
    font-family: Tex-reg;
    li{
        label{
            display: inline-block;
        }
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
          left: -9999px;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: 2em;
          cursor: pointer;
          color: #aaa;
        }  

        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: '';
          position: absolute;
          left:0; top: .2em;
          width: 1.4em; height: 1.4em;
          border: 1px solid #aaa;
          border-radius: 50%;
          background: none;
          box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: '\f00c';
          font-family: FontAwesome;
          position: absolute;
          top: .6em; left: .3em;
          font-size: .9em;
          line-height: 0.8;
          color: $primary;
          transition: all .2s;
        }
        
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }
        [type="checkbox"]:checked + label:after {
          opacity: 1;
          transform: scale(1);
        }

        // disabled?
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
          background-color: #ddd;
        }
        [type="checkbox"]:disabled:checked + label:after {
          color: #999;
        }
        [type="checkbox"]:disabled + label {
          color: #aaa;
        }

        [type="checkbox"]:checked:focus + label:before,
        [type="checkbox"]:not(:checked):focus + label:before {}

    }
}

hr{
    height: 1px;
    display: block;
    border-bottom: 1px solid #171717;
    margin: 2em 0;
}

.ns-crop-area {
    background: black;
    overflow: hidden;
    width: 320px;
    height: 320px;
    margin: 1em auto;
    border: 1px solid #222;
}
.ns-crop-image-preview{
    width: 180px;
    height: 180px;
    border: 1px solid #222;
}

.ns-service-select-btn {
    display: block;
    height: 4em;
    width: 4em;
    padding: 3.5em;
    margin: 1em auto;
    border: 4px solid #171717;
    border-radius: 100%;
    background-position: center center;
    background-size: 5em;
    background-repeat: no-repeat;
    background-color: transparent;
    transition: all .4s;
    &:hover{
        border-color: white;
        background-color: linear-gradient(
        rgba(0, 0, 0, 0.9), 
        rgba(0, 0, 0, 0.9)
        );
//        background-image:  url('../img/layout/eat.png');
    }
    
    &.select-eat {
        background-image: url('../img/layout/eat.png');
    }    
    &.select-drink {
        background-image: url('../img/layout/drink.png');
    }   
    &.select-attend {
        background-image: url('../img/layout/attend.png');
    }
}

.ns-spot-price-select{
    display: block;
    line-height: 1.4em;
    span {
        font-family: Tex-reg;
        cursor: pointer;
        font-size: 1.4em;
        margin: 0 .2em;
        
        &.preview {
            color: palegreen;
        }
    }
}

.ns-terms {
    height: 500px;
    overflow: scroll;
    margin: 1em 0;
}
@media (min-width: 34em) {

//    .ns-service-select-btn{
//         height: 4em;
//         width: 4em;
//         padding: 3.5em;
//         background-size: 4em;
//    }

}
//@media (min-width: 48em) {}
//@media (min-width: 62em) {}
//@media (min-width: 75em) {}