
.nsb{
   height: 100%;
}
.nsb-header {
    background: url('../img/layout/nsb-bg.jpg');
    background-attachment: fixed;
    background-position: center bottom;
    background-size: cover;
    
    height: 100%;
    text-align: center;
    color: white;
    h1{
        font-family: Tex-bold !important;
        text-transform: uppercase;
    }
    h6{}

    input {
        border-color: #ddd !important;
    }
}
.nsb-advertise,
.nsb-promote,
.nsb-engage{
    display: flex;
    flex-direction: column;
    padding: 1.5em 0;
    h4{
        line-height: 1.4em;
        font-family: Tex-bold !important;
    }

    p{
        font-family: Tex-reg;
    }
    img {
        opacity: 0;
        -webkit-animation-duration: .7s;
        animation-duration: .7s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}
.nsb-advertise{
    background: #151515;
    color: #eee;
}

.nsb-promote {
    background: #000;
    color: #eee;
}
.nsb-engage {
    background: #151515;
    color: white;
}
.nsb-getstarted{
    h1{
        margin: 1em 0;
        font-size: 3em;
        text-transform: uppercase;
        font-family: Tex-bold !important;
    }
    .card {
        background: #171717;
        padding: 3em 0;
        margin: 3em 0;
        position: relative;

        .step{
            position: absolute;
            display: block;
            font-family: Tex-reg;
            font-size: 2em;
            top: -1em;
            left: .5em;
            text-shadow: 2px 2px 2px #000;
        }

        ul {
            text-align: left !important;
            list-style: none;
        }
        div {
            width: 310px;
        }
    }
}

.derp{
    display:flex;
    align-items:center;
}

@media (min-width: 34em) { 
.nsb-header{
    input {
        border-color: white;
    }
    h6{
        padding-top: 15%;   
    }
    h1 {
        text-shadow: 1px 1px 1px #000;
        font-size: 4em;
    }
    
}
.nsb-advertise,
.nsb-promote,
.nsb-engage{
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 50%;
}
.nsb-getstarted{
    h1{
        margin: 2em 0;
        text-transform: uppercase;
    }
    .card {

        .step{
            font-size: 3em;
            top: -1em;
            left: .5em;
        }

        ul {
            text-align: left !important;
            list-style: none;
        }
        div {
            width: 400px;
        }
    }
  }
}


.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
@keyframes fadeInRight {
    0% {
        transform: translateX(20px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@-moz-keyframes fadeInRight {
    0% {
        -moz-transform: translateX(20px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes "fadeInRight" {
    0% {
    opacity: 0;
        -webkit-transform: translateX(20px);
    }
    100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    }
}
.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
@keyframes fadeInLeft {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@-moz-keyframes fadeInLeft {
    0% {
        -moz-transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes "fadeInLeft" {
    0% {
    opacity: 0;
        -webkit-transform: translateX(-20px);
    }
    100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    }
}
